<template>
  <div>
    <el-popover ref="popoverDropdown" placement="bottom" width="600" trigger="click">
      <div style="overflow-y: auto; max-height: 500px">
        <el-row style="text-align: center">
          <el-radio-group v-model="value.type">
            <el-radio-button label="keyValue">Key-Value</el-radio-button>
            <el-radio-button label="stateData">Data</el-radio-button>
          </el-radio-group>
        </el-row>
        <el-divider>Input</el-divider>
        <el-checkbox
          v-model="value.options.multiselect"
          label="Allow Multiselect"
          border
          style="margin-bottom: 10px"
        ></el-checkbox>
        <div v-if="value.type === 'keyValue'">
          <el-row
            v-for="(obj, index) in value.data"
            :key="index"
            type="flex"
            justify="space-between"
          >
            <el-col :span="11">
              <el-input placeholder="Key" v-model="obj.key" style="width: 100%"></el-input>
            </el-col>
            <el-col :span="11">
              <el-input placeholder="Value" v-model="obj.value" style="width: 100%"></el-input>
            </el-col>
            <el-col :span="1">
              <el-button @click="deleteKeyValue(index)" type="text">
                <i class="el-icon-delete"></i>
              </el-button>
            </el-col>
          </el-row>
          <el-button @click="addKeyValue" size="small" type="success">
            <i class="el-icon-plus"></i>
          </el-button>
        </div>
        <div v-else-if="value.type === 'stateData'">
          <el-input placeholder="Variable Name" v-model="value.data">
            <template slot="prepend">
              Key
              <el-tooltip
                content="State variable to be used as dropdown data (need to have ke-value pair: [{key: 'foo', value: 123}])"
                placement="top"
              >
                <i class="el-icon-question" style="color: #909399" />
              </el-tooltip>
            </template>
          </el-input>
        </div>
        <el-divider>Data Handler</el-divider>
        <el-input placeholder="State Variable" v-model="value.captureAs">
          <template slot="prepend">
            Capture As
            <el-tooltip
              content="State variable to hold user's selected dropdown value"
              placement="top"
            >
              <i class="el-icon-question" style="color: #909399" />
            </el-tooltip>
          </template>
        </el-input>
        <el-checkbox
          v-model="value.options.saveAsBoolean"
          label="Save As Boolean"
          border
          style="margin: 5px 0"
        ></el-checkbox>
        <el-tooltip
          :content="booleanCheckboxTooltipContent"
          placement="top"
          style="margin-left: 5px"
        >
          <i class="el-icon-question" style="color: #909399" />
        </el-tooltip>
        <el-input placeholder="Submit Label" v-model="value.label" style="margin: 10px 0">
          <template slot="prepend">Button Label</template>
        </el-input>
        <el-row v-if="value.next">
          <el-steps
            direction="vertical"
            :active="2"
            style="width: 24px; height: 100px; margin: auto"
          >
            <el-step />
            <el-step />
            <el-button v-if="value.next" type="text" icon="el-icon-close" @click="removeNext">
              Link
            </el-button>
          </el-steps>
          <EventNodeSelector v-model="value.next" :previous-node="value" />
        </el-row>
        <div v-else style="width: 100%; text-align: center">
          <el-button type="text" icon="el-icon-plus" @click="addNext">Next step</el-button>
        </div>
      </div>

      <el-button
        :disabled="!value.enabled"
        slot="reference"
        class="dropdown-button"
        style="position: relative"
        @click="togglePopover"
      >
        <div style="position: absolute; top: 20%; left: 4px">
          <el-switch v-model="value.enabled"></el-switch>
        </div>
        <span>Dropdown <i class="el-icon-caret-bottom"></i></span>
      </el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  components: {
    EventNodeSelector: () => import("@/components/EventNodeSelector/Index"),
  },
  data() {
    return {
      popoverOpened: false,
    };
  },
  props: {
    value: {
      default() {
        return {
          enabled: false,
          type: null,
          options: { multiselect: false, saveAsBoolean: false },
        };
      },
    },
  },
  computed: {
    booleanCheckboxTooltipContent() {
      return `This will capture user choice into stateKey [${this.value.captureAs}_value] = true`;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (!newValue.enabled) {
          this.$refs.popoverDropdown.doClose();
          this.popoverOpened = false;
        }
        if (newValue.type === "keyValue" && !Array.isArray(newValue.data)) {
          this.$set(this.value, "data", [{ key: "", value: "" }]);
        }
        if (newValue.type === "stateData" && Array.isArray(newValue.data)) {
          this.$set(this.value, "data", "");
        }
      },
      deep: true,
    },
  },
  methods: {
    togglePopover() {
      this.popoverOpened = !this.popoverOpened;
      this.$emit("addSelection");
    },
    addKeyValue() {
      if (Array.isArray(this.value.data)) {
        this.value.data.push({ key: "", value: "" });
      }
    },
    deleteKeyValue(index) {
      if (Array.isArray(this.value.data)) {
        this.value.data.splice(index, 1);
      }
    },
    addNext() {
      this.$set(this.value, "next", {
        type: "event",
        event: null,
        data: null,
      });
    },
    removeNext() {
      this.$delete(this.value, "next");
    },
  },
};
</script>

<style scoped>
.dropdown-button {
  width: 100%;
}
.dropdown-button:not([disabled]) {
  color: #0084ff;
}
</style>
